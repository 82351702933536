<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">Rekapitulasi Nilai Pemda Provinsi</h3>
        <div class="card-toolbar">

        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <!--begin::Item-->
        <div class="mb-12">
          <!--begin::Content-->
          <div>

            <div style="overflow-x:auto;">
              <table id="example" class="table table-striped table-bordered" style="border-collapse: collapse;width:100%">
                <thead>
                  <tr>
                    <th rowspan="2">No</th>
                    <th rowspan="2">Nama Provinsi</th>
                    <th style="text-align:center;" colspan="3">Kinerja PTSP</th>
                    <th>Kinerja PPB</th>
                    <th rowspan="2">Nilai Akhir</th>
                    <th style="text-align:center;" colspan="3">Aktivitas Akun</th>
                    <th style="text-align:center;" colspan="2">Verifikasi Dokumentatif</th>
                    <th style="text-align:center;" colspan="2">Verifikasi Lapangan</th>
                    <th style="text-align:center;" colspan="2">File BA</th>
                    <th style="text-align:center;" colspan="3">Predikat</th>
                  </tr>
                  <tr>
                    <th>PM PTSP</th>
                    <th>Penilaian Organisasi Pengusaha</th>
                    <th>Total</th>
                    <th>PM PPB</th>
                    <th>PM PTSP</th>
                    <th>PM PPB</th>
                    <th>Organisasi Pengusaha</th>
                    <th>PM PTSP</th>
                    <th>PM PPB</th>
                    <th>PM PTSP</th>
                    <th>PM PPB</th>
                    <th>PM PTSP</th>
                    <th>PM PPB</th>
                    <th>PTSP</th>
                    <th>PPB</th>
                    <th>Akhir</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in totskor" :key="index">
                    <td></td>
                    <td>
                      <div style="width:150px">{{ row.daerah }}</div>
                    </td>
                    <td>
                      <div style="width:120px">{{ format_desimal(row.total_skor_pm) }}&nbsp; &nbsp;<b-button
                          v-if="row.total_skor_pm != null" @click="urlpm(row.idptsp)" size="sm"
                          style="background-color:#F9F9F9;"><i
                            class="flaticon-eye"></i></b-button></div>
                    </td>
                    <td>
                      <div style="width:120px">{{ format_desimal(row.total_skor_asosiasi) }}&nbsp; &nbsp;<b-button
                          v-if="row.total_skor_asosiasi != null" @click="urlhipmi(row.idptsp)" size="sm"
                          style="background-color:#F9F9F9;"><i
                            class="flaticon-eye"></i></b-button></div>
                    </td>
                    <td>
                      <div style="width:80px">{{ format_desimal(row.total_ptsp) }}</div>
                    </td>
                    <td>
                      <div style="width:120px">{{ format_desimal(row.total_skor_ppb) }}&nbsp; &nbsp;<b-button
                          v-if="row.total_skor_ppb != null" @click="urlppb(row.idptsp)" size="sm"
                          style="background-color:#F9F9F9;"><i
                            class="flaticon-eye"></i></b-button></div>
                    </td>
                    <td>
                      <div style="width:80px">{{ format_desimal(row.total_akhir) }}</div>
                    </td>
                    <td>
                      <div v-if="row.login_pm === 'Belum Login'">
                        <b-badge class="mr-1" variant="danger">Belum Login</b-badge>
                      </div>
                      <div v-else-if="row.login_pm === 'Sudah Login, Sudah Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Sudah Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else-if="row.login_pm === 'Sudah Login, Belum Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Belum Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="mr-1" variant="success">Sudah Melakukan Penilaian</b-badge>
                      </div>
                    </td>
                    <td>
                      <div v-if="row.login_ppb === 'Belum Login'">
                        <b-badge class="mr-1" variant="danger">Belum Login</b-badge>
                      </div>
                      <div v-else-if="row.login_ppb === 'Sudah Login, Sudah Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Sudah Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else-if="row.login_ppb === 'Sudah Login, Belum Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Belum Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="mr-1" variant="success">Sudah Melakukan Penilaian</b-badge>
                      </div>
                    </td>
                    <td>
                      <div v-if="row.login_asosiasi === 'Belum Login'">
                        <b-badge class="mr-1" variant="danger">Belum Login</b-badge>
                      </div>
                      <div v-else-if="row.login_asosiasi === 'Sudah Login, Sudah Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Sudah Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else-if="row.login_asosiasi === 'Sudah Login, Belum Melakukan Penlaian'">
                        <b-badge class="mr-1" variant="warning">Sudah Login, Belum Melakukan Penlaian</b-badge>
                      </div>
                      <div v-else>
                        <b-badge class="mr-1" variant="success">Sudah Melakukan Penilaian</b-badge>
                      </div>
                    </td>
                    <td>
                      <div style="width:120px" v-if="row.login_verif_dok_pm === 'Belum'">
                        <b-badge class="mr-1" variant="danger">Belum</b-badge>
                      </div>
                      <div style="width:120px" v-else-if="row.login_verif_dok_pm === 'Sebagian'">
                        <b-badge class="mr-1" variant="warning">Sebagian</b-badge>
                      </div>
                      <div style="width:120px" v-else>
                        <b-badge class="mr-1" variant="success">Sudah</b-badge>
                      </div>
                    </td>
                    <td>
                      <div style="width:120px" v-if="row.login_verif_dok_ppb === 'Belum'">
                        <b-badge class="mr-1" variant="danger">Belum</b-badge>
                      </div>
                      <div style="width:120px" v-else-if="row.login_verif_dok_ppb === 'Sebagian'">
                        <b-badge class="mr-1" variant="warning">Sebagian</b-badge>
                      </div>
                      <div style="width:120px" v-else>
                        <b-badge class="mr-1" variant="success">Sudah</b-badge>
                      </div>
                    </td>
                    <td>
                      <div style="width:120px" v-if="row.login_verif_lap_pm === 'Belum'">
                        <b-badge class="mr-1" variant="danger">Belum</b-badge>
                      </div>
                      <div style="width:120px" v-else-if="row.login_verif_lap_pm === 'Sebagian'">
                        <b-badge class="mr-1" variant="warning">Sebagian</b-badge>
                      </div>
                      <div style="width:120px" v-else>
                        <b-badge class="mr-1" variant="success">Sudah</b-badge>
                      </div>
                    </td>
                    <td>
                      <div style="width:120px" v-if="row.login_verif_lap_ppb === 'Belum'">
                        <b-badge class="mr-1" variant="danger">Belum</b-badge>
                      </div>
                      <div style="width:120px" v-else-if="row.login_verif_lap_ppb === 'Sebagian'">
                        <b-badge class="mr-1" variant="warning">Sebagian</b-badge>
                      </div>
                      <div style="width:120px" v-else>
                        <b-badge class="mr-1" variant="success">Sudah</b-badge>
                      </div>
                    </td>
                    <td style="text-align:center">
                      <div style="width:120px" v-if="row.file_ba_pm == null || row.file_ba_pm === ''">

                      </div>
                      <div style="width:120px" v-else>
                        <a :href="row.file_ba_pm" target="_blank"
                          class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i
                            class="fa fa-download" aria-hidden="true" /></a>
                      </div>
                    </td>
                    <td style="text-align:center">
                      <div style="width:120px" v-if="row.file_ba_ppb == null || row.file_ba_ppb === ''">

                      </div>
                      <div style="width:120px" v-else>
                        <a :href="row.file_ba_ppb" target="_blank"
                          class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i
                            class="fa fa-download" aria-hidden="true" /></a>
                      </div>
                    </td>
                    <td><b-badge class="mr-1" :style="{ 'background-color': row.warna_predikat_ptsp }"
                        variant="success">{{ row.predikat_ptsp }}</b-badge></td>
                    <td><b-badge class="mr-1" :style="{ 'background-color': row.warna_predikat_ppb }"
                        variant="success">{{ row.predikat_ppb }}</b-badge></td>
                    <td><b-badge class="mr-1" :style="{ 'background-color': row.warna_predikat_akhir }"
                        variant="success">{{ row.predikat_akhir }}</b-badge></td>
                    <!-- <td></td> -->
                    <!-- <td>t.nixon@datatables.net</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Item-->
      </div>
      <!--end: Card Body-->
    </div>
  </div>
  <!-- </div>
  </div> -->
</template>
<style>
div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<style lang="">
  
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      test: 'hai',
      url: localStorage.getItem('baseapi'),
      token: localStorage.getItem('id_token'),
      totskor: [],
      search: '',
      headers: [
        {
          text: 'Nama',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: 'Ket', value: 'ket' },
        { text: 'Icon', value: 'icon' },
        { text: 'Aksi', value: '' },

      ],
    }
  },
  components: {

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Rekapitulasi" }]);
    this.loaddata()

  },
  methods: {
    loaddata() {
      if (this.user.id_role === 'super_user' || this.user.id_role === 'tim_penilai' || this.user.id_role === 'operator_nomine' || this.user.id_role === 'qc' || this.user.id_role === 'pendamping_qc' || this.user.id_role === 'tim_teknis_penilai' || this.user.id_role === 'dpb' || this.user.id_role === 'kemenkeu' || this.user.id_role === 'expert') {
        axios.get(this.url + "/rekapitulasi_pemda/provinsi?tahun=2024", {
          headers: {
            'xth': this.token
          }
        })
          .then(response => {
            this.totskor = response.data;
            this.initDatatable();
          }).catch(error => {
            return error
          });
      } else {
        this.$router.push({ name: "dashboard" })
      }


    },
    format_desimal(value) {
      if (value) {
        return value.replace(".", ",");
      }
    },
    initDatatable() {
      setTimeout(() => {
        var t = $('#example').DataTable({
          "pagingType": "full_numbers",
          "language": {
            "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            "paginate": {
              "first": "Awal",
              "last": "Akhir",
              "next": "Selanjutnya",
              "previous": "Sebelumnya"
            },
          },
          scrollX: true,
          fixedColumns: {
            left: 2,
          },
          dom: 'Bfrtip',
          // "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          //     //debugger;
          //     var index = iDisplayIndexFull + 1;
          //     $("td:first", nRow).html(index);
          //     return nRow;
          //   },
          // columnDefs: [{
          //   targets: 0,
          //   searchable: false,
          //   orderable: false,
          //   render: function(data, type, row, meta) {
          //   console.log(meta.row);
          //   // console.log(type == 'export'? meta.row : data);
          //   //return type == 'export'? meta.row + 1 : data;
          //   return meta.row + 1
          //   }
          // }],
          buttons: [
            // 'excel', 'pdf',
            {
              extend: 'excelHtml5',
              title: 'REKAPITULASI PENILAIAN PEMDA PROVINSI KEMENTERIAN INVESTASI/BKPM',
              text: 'Excel',
              exportOptions: {
                columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
                orthogonal: 'export',
                format: {
                  header: function (data, columnIdx) {
                    if (columnIdx == 7 || columnIdx == 8 || columnIdx == 9) {
                      return 'Aktivitas ' + data;
                    }
                    else if (columnIdx == 10 || columnIdx == 11) {
                      return 'Verifikasi Dokumentatif ' + data;
                    }
                    else if (columnIdx == 12 || columnIdx == 13) {
                      return 'Verifikasi Lapangan ' + data;
                    }
                    else if (columnIdx == 14 || columnIdx == 15) {
                      return 'File BA ' + data;
                    }
                    else if (columnIdx == 16 || columnIdx == 17 || columnIdx == 18) {
                      return 'Predikat ' + data;
                    }
                    else {
                      return data;
                    }
                  },
                  body: function (data, row, column, node) {
                    data = $('<p>' + data + '</p>').text();
                    return data.replace(',', '.');
                  },
                }
              },
              filename: function () {
                var today = new Date();
                // var n = d.getTime();
                var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

                return 'Rekapitulasi Nilai Pemda Provinsi ' + date;
              },
            },
            {
              extend: 'pdf',
              text: 'PDF',
              orientation: 'landscape',
              title: 'REKAPITULASI PENILAIAN PEMDA PROVINSI KEMENTERIAN INVESTASI/BKPM',
              exportOptions: {
                columns: [1, 2, 3, 4, 5, 6, 7, 8]
              },
              filename: function () {
                var today = new Date();
                // var n = d.getTime();
                var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

                return 'Rekapitulasi Nilai Pemda Provinsi ' + date;
              },
            }
          ],
          "lengthMenu": [
            [10, 25, 50, -1],
            [10, 25, 50, "All"]
          ],
          order: [[6, 'desc']],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,

        });
        // t.on( 'order.dt search.dt', function () {
        // t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
        //     cell.innerHTML = i+1;
        // } );
        // } ).draw();
        t.on('order.dt search.dt', function () {
          let i = 1;

          t.cells(null, 0, { search: 'applied', order: 'applied' }).every(function (cell) {
            this.data(i++);
          });
        }).draw();
      }, 300)
    },
    urlpm(kode_wilayah) {
      let routeData = this.$router.resolve(
        {
          path: `/rekapitulasi/detailpm/${kode_wilayah}`,
          // query: {'dataParameter': kode_wilayah}
        });
      window.open(routeData.href, '_blank');
      //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
    },
    urlppb(kode_wilayah) {
      let routeData = this.$router.resolve(
        {
          path: `/rekapitulasi/detailppb/${kode_wilayah}`,
          // query: {'dataParameter': kode_wilayah}
        });
      window.open(routeData.href, '_blank');
      //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
    },
    urlhipmi(kode_wilayah) {
      let routeData = this.$router.resolve(
        {
          path: `/rekapitulasi/detailhipmi/${kode_wilayah}`,
          // query: {'dataParameter': kode_wilayah}
        });
      window.open(routeData.href, '_blank');
      //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created() {
    //   this.getuser(this.user)
    // this.getlocaldata()
  }
};
</script>
